import React, { Fragment, useState } from "react";
import "../Home/home.scss";
import "./get-quote.scss";
import "../Contact/contact.scss";
import "../Portfolio/portfolio.scss";
import { Link } from "react-router-dom";
import SendMsgModal from "../Modals/SendMsgModal";
import { Helmet } from "react-helmet-async";

const GetQuote = () => {
  const [showModal, setShowModal] = useState(false)

  const onSubmit = () => {
    setShowModal(true)
  }
  return (
    <Fragment>
      <Helmet>
        <title>Get Quote</title>
        <meta name="description" content="Get Quote" />
      </Helmet>
      <div>
        <div className={`main-content pb-5 get-quote-ui`}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 pt-3 pe-md-5">
                <div className="w-100 get-quote-content">
                  <div className="custom-div text-center py-4">
                    <h1 className="mb-3">Get a Quote</h1>
                    <p className="d-grid">
                      <span>Providing, Innovative {"&"} Technilogical </span>{" "}
                      <span> Business Solutions.</span>
                    </p>
                  </div>
                  <div
                    className="get-quote-bg text-center left-side-info"
                    style={{
                      backgroundImage: `url("assets/imgs/new-quote-bg.png")`,
                    }}
                  ></div>
                </div>
              </div>

              {/* Form html starts here */}
              <div className="col-md-6 col-sm-12 pt-3">
                <div className="w-100">
                  <form className={`form-border-top contact-form`}>
                    <div className="row pt-3 pt-md-0">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name *"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email address *"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile Phone"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="dropdown">
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Select a Service
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link className="dropdown-item" to="/">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="dropdown">
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Budget
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link className="dropdown-item" to="/">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="dropdown">
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Time to Start
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link className="dropdown-item" to="/">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Requirement *"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="
                        Brief about the project"
                            rows={3}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-12 position-relative">
                        <div className="form-control py-0">
                          <div className="upload-btn-wrapper">
                            <button className="btn ps-4">
                              <img
                                src="assets/imgs/cv-file.png"
                                className="cv-file ms-3"
                                alt="Asia Software Solutions"
                              />{" "}
                              Choose your file (2 Mib max)
                            </button>
                            <input type="file" name="myfile" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 text-right mt-2">
                        <img
                          src="assets/imgs/captcha.png"
                          alt="Asia Software Solutions"
                          className="me-5 captcha"
                        />
                      </div>
                      <div className="col-12 text-right mt-2">
                        <button
                          type="button"
                          className={`apply-btn btn text-white px-5 my-4`}
                          // data-bs-toggle="modal"
                          // data-bs-target="#send-msg-modal"
                          onClick={() => onSubmit()}
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* Form html ends here */}
            </div>
          </div>
        </div>
        {/* Modal html */}
        <SendMsgModal open={showModal} closeModal={() => setShowModal(false)} />
      </div>
    </Fragment>
  );
};

export default GetQuote;
