import React, { useState } from 'react'

interface accordionData {
    title: string,
    content: string
}

const AccordionComponent = ({ title, content }: accordionData) => {
    const [showAcc, setShowAcc] = useState(false);

    const handleAccordion = () => {
        setShowAcc(!showAcc);
    }

    return (
        <div className="card">
            <div className="card-head" id="business">
                <div
                    className={`card-title mb-0 ${showAcc ? 'collapsed' : ''}`}
                    data-toggle="collapse"
                    data-target="#business-collapse"
                    aria-expanded="true"
                    aria-controls="business-collapse"
                    onClick={() => handleAccordion()}
                >
                    {title}
                </div>
            </div>

            <div
                id="business-collapse"
                className={`collapse ${showAcc ? 'show' : ''}`}
                aria-labelledby="business"
            >
                <div className="card-body">
                    {content}
                </div>
            </div>
        </div>
    )
}

export default AccordionComponent
