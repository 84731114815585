import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import "../Home/home.scss";

const ReactDeveloper = () => {
  return (
    <Fragment>
      <Helmet>
        <title>ReactJS Developers</title>
        <meta name="description" content="ReactJS Developers" />
      </Helmet>
      <div>
        <div className={`hire-ui`}>
          <div className={`main-banner`} style={{ backgroundImage: `url("../../../assets/imgs/page-bg.png")` }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-7 col-sm-12 pe-4">
                  <div className={`left-side-info`}>
                    <h1 className="mb-3">Hire ReactJS Developer</h1>
                    <p className="pe-md-5 me-lg-5">
                      Hire dedicated ReactJS programmers from Asia Software
                      Solutions on an hourly (Pay-as-you-go) or full-time basis
                      or part time for dynamic ReactJS web applications. We’re a
                      leading ReactJS development company that has successfully
                      deployed complex solutions for businesses of all kinds,
                      across industries. We are committed to help you with 100%
                      integrity, right from selecting a developer of your choice
                      to the final product delivery.
                    </p>
                  </div>
                </div>

                <div className="col-md-5 col-sm-12 mt-md-0 mt-4">
                  <img
                    src="../../../assets/imgs/about-asset-img.png"
                    className="img-width"
                    alt="Asia Software Solutions"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`dev-process custom-padding`}>
            <div className="container">
              <div className="text-center">
                <span className="small-font-head">
                  WHY DO PEOPLE CHOOSE US?
                </span>
                <h5 className={`section-heading mt-4 d-grid`}>
                  <span>Advantages of Hiring ReactJS Developers</span>{" "}
                  <span>@asiasoftsol</span>
                </h5>
              </div>
              <div className={`row row-equal-h`}>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Custom ReactJS Development</p>
                    <p>
                      Our ReactJS programmers develop dynamic, robust, secure
                      software apps for complex business scenarios.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d1.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>UI/UX Design {"&"} Development</p>
                    <p>
                      Hire ReactJS coders from us to avail full-fledged services
                      to build your app with captivating '{"&"}' intuitive
                      UI/Ux.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d2.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>
                      API Development {"&"} Integration
                    </p>
                    <p>
                      We develop RESTful APIs to faster data transfer on your
                      app to make it highly functional for complex systems.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d3.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>
                      ReactJS Quality Check {"&"} Testing
                    </p>
                    <p>
                      Code audit is performed to build a stronger foundation
                      along with manual and automated QA testing services for
                      faster, quality product release.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d4.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>ReactJS Migration {"&"} Upgrade</p>
                    <p>
                      Our ReactJS programmers can migrate/ integrate your
                      existing web applications to the ReactJS framework without
                      any loss of data with security.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d5.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>App Maintenance {"&"} Support</p>
                    <p>
                      We provide a contract system and support services to make
                      ReactJS web apps compatible with diverse browsers to
                      ensure top performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ReactDeveloper;
