import axios, { AxiosResponse } from "axios"
const rootUrl = process.env.REACT_APP_BASE_API_URL

export class ApiService {
    public static async getCareersData(url: string): Promise<AxiosResponse> {               
        const response: any = await axios.get(`${rootUrl}/${url}`)                              
        return response.data.data;
    }

    public static async getJobDetails(url: string): Promise<AxiosResponse> {               
        const response: any = await axios.get(`${rootUrl}/${url}`)                      
        return response.data.data;
    }

    public static async postOpeningJobApply(url: string, data:any): Promise<AxiosResponse> {                       
        const response: any = await axios.post(`${rootUrl}/${url}`,data )                      
        return response;
    }

    public static async sendContactDetails(url: string, data:any): Promise<AxiosResponse> {        
        const response: any = await axios.post(`${rootUrl}/${url}`,data )                      
        return response;
    }

}