import { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ApiService } from "../../utilities/api.service";
import ReCAPTCHA from "react-google-recaptcha";
import "../Home/home.scss";
import "./detail.scss";
import "../Contact/contact.scss";
import "../Portfolio/portfolio.scss";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import SendMsgModal from "../Modals/SendMsgModal";

interface jobDetailsModel {
  benefits: string,
  description: string,
  duration: string,
  id: number,
  jobLocation: string,
  location: string,
  maxSalary: string,
  minSalary: string,
  note: string,
  numberofOpenings: number,
  role: string,
  salary: number,
  sector: string,
  technicalRequirements: string,
  title: string,
}

const CareersDetail = () => {
  const history = useHistory();
  const location = useLocation()
  const state = location.state;
  const [showModal, setShowModal] = useState(false);
  const [jobDetails, setJobDetails] = useState<jobDetailsModel>();
  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    if (state) {
      ApiService.getJobDetails(`opening/get-by-id?id=${state}`).then((data: any) => {
        setJobDetails(data)
      })
    }
  }, [state])

  const capitalWords = (item: string) => {
    return item.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
  }

  const splitString = (item: string) => {
    let arr = item.split(',');
    return arr
  }

  const handleOnChange = (value: any) => {
    if (value) {
      console.log("Verified")
    } else {
      console.log("Not Verified")
    }
  }

  const onSubmit = (data: any) => {
    const formData = new FormData();
    const selectedFile = data.cvFile[0];
    const selectedFIleName = selectedFile.name

    formData.append(
      "cvFile",
      selectedFile,
      selectedFIleName
    );

    const finalData = { ...data, cvFile: null, cvFilePath: "" }

    try {
      ApiService.postOpeningJobApply(`opening/apply-job`, finalData).then((res) => {
        if (res.status === 200) {
          setShowModal(true)
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>Career Details</title>
        <meta name="description" content="Career Details" />
      </Helmet>
      <div>
        <div className={`main-content pb-5`}>
          <div className="container">
            {jobDetails && (
              <div className="row">
                <div className="col-md-8 col-lg-8 col-sm-12 border-bottom">
                  <div>
                    <button type="button" className={`main-btn mb-3`} onClick={() => history.goBack()}>
                      <i className="fa fa-arrow-left me-2" aria-hidden="true"></i>{" "}
                      BACK
                    </button>
                    <h4 className="fw-bold mb-4 mt-3">
                      {capitalWords(jobDetails.title)}
                      <span> (AN-13)</span>
                    </h4>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12"></div>

                {/* Next columns */}
                <div className="col-md-8 col-lg-8 col-sm-12 pt-3">
                  <div>
                    <p>
                      {jobDetails.note}
                    </p>
                    <p className="mb-1">
                      <span className="fw-bold">Role:</span>
                      <span> {capitalWords(jobDetails.role)}</span>
                    </p>
                    <p className="mb-1">
                      <span className="fw-bold">Joiner:</span>
                      <span> Immediate are preffered</span>
                    </p>
                    <p className="mb-1">
                      <span className="fw-bold">Qualification:</span>
                      <span> Computer Engineering, Computer Science or related IT field</span>
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12 pt-3">
                  <div className="d-flex d-md-block d-lg-block justify-content-between">
                    <div>
                      <div>
                        <p className="fw-bold mb-0">Location</p>
                        <p className="mb-2">{capitalWords(jobDetails.location)}</p>
                      </div>
                      <div>
                        <p className="fw-bold mb-0">Sector</p>
                        <p className="mb-2">{jobDetails.sector.toLocaleUpperCase()}</p>
                      </div>
                      <div>
                        <p className="fw-bold mb-0">Salary</p>
                        <p className="mb-2">{jobDetails.salary && `${jobDetails.salary} per annum`}</p>
                      </div>
                      <div>
                        <p className="fw-bold mb-0">Experience</p>
                        <p className="mb-2">3+ Years</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  <h4 className="fw-bold mb-4 mt-3">Technical Requirement</h4>
                  <div className="row technical-req">
                    <div className="col-md-12 col-sm-12">
                      <ul className="ps-0">
                        {splitString(jobDetails.technicalRequirements).map((item, idx) => {
                          return (
                            <li key={idx}>{capitalWords(item)}</li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                {/* Form html starts here */}
                <div className="col-sm-12 pt-3">
                  <h4 className="fw-bold mb-4 mt-1">Apply Now</h4>
                  <form className={`form-border-top contact-form`} onSubmit={handleSubmit(onSubmit)}>
                    <div className="row pt-3 pt-md-0">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First name"
                            {...register("firstName", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last name"
                            {...register("lastName", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email address"
                            {...register("email", {
                              required: true,
                              pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                              }
                            })}
                          />
                          {errors?.emailAddress?.message && (
                            <p>{errors.emailAddress?.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            type="tel"
                            className="form-control"
                            placeholder="Phone Number"
                            {...register("phoneNumber", {
                              required: true,
                              pattern: {
                                value: /^\d{10}$/,
                                message: 'Please only enter numeric characters only and 10 digits (Allowed input:0-9)',
                              }
                            })}
                          />
                          {errors?.phoneNumber?.message && (
                            <p>{errors.phoneNumber?.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Linkdin address"
                            {...register("linkedInAddress", { required: true })}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-6 col-sm-12">
                        <div className="form-group position-relative">
                          <img
                            src="assets/imgs/cv-file.png"
                            className={`cv-file`}
                            alt="Asia Software Solutions"
                          />
                          <input
                            type="file"
                            className="form-control"
                            placeholder="Choose your file (2 Mib max)"
                            {...register("cvFile", { required: true })}
                          />
                        </div>
                      </div> */}

                      <div className="col-md-6 col-12 position-relative">
                        <div className="form-control py-0">
                          <div className="upload-btn-wrapper">
                            <button className="btn ps-4 bg-transparent text-gray">
                              <img
                                src="assets/imgs/cv-file.png"
                                className={`cv-file`}
                                alt="Asia Software Solutions"
                              />{" "}
                              Choose your file (2 Mib max)
                            </button>
                            <input type="file" name="myfile" {...register("cvFile", { required: true })} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="
                        Describe your Work Areas, Roles and Responsibilities involved in your current job"
                            rows={3}
                            {...register("coverLetter", { required: true })}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-12 text-right mt-2">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={handleOnChange}
                        />
                        <button
                          type="submit"
                          className={`apply-btn btn text-white px-4 my-4`}
                        // data-bs-toggle="modal"
                        // data-bs-target="#send-msg-modal"
                        >
                          APPLY NOW
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* Form html ends here */}
              </div>
            )}
          </div>
        </div>
        {/* Modal html */}
        <SendMsgModal open={showModal} closeModal={() => setShowModal(false)} />
      </div>
    </Fragment>
  );
};

export default CareersDetail;
