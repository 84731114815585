import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import "../Home/home.scss";

const HtmlDev = () => {
  return (
    <Fragment>
      <Helmet>
        <title>HTML Developers</title>
        <meta name="description" content="HTML Developers" />
      </Helmet>
      <div>
        <div className={`hire-ui`}>
          <div className={`main-banner`} style={{ backgroundImage: `url("../../../assets/imgs/page-bg.png")` }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-7 col-sm-12 pe-4">
                  <div className={`left-side-info`}>
                    <h1 className="mb-3">Hire HTML5 Developers</h1>
                    <p className="pe-md-5 me-lg-5">
                      Our developers have 4+ years of experience in building
                      dynamic websites and apps, with multi-platform capabilities,
                      for global businesses using the latest HTML5 tools.
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12 mt-md-0 mt-4">
                  <img
                    src="../../../assets/imgs/about-asset-img.png"
                    className="img-width"
                    alt="Asia Software Solutions"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`dev-process custom-padding`}>
            <div className="container">
              <div className="text-center">
                <span className="small-font-head">SERVICES YOU CAN GET BY</span>
                <h5 className={`section-heading mt-4 d-grid`}>
                  <span>Hiring HTML5 Developers from Asia Software</span>{" "}
                  <span>Solutions</span>
                </h5>
              </div>
              <div className={`row row-equal-h`}>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Multi-platform Apps</p>
                    <p>
                      We build multi-platform web apps using HTML5 to deliver
                      cross-platform capabilities with native app like elements
                      and feel.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d1.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Web Development</p>
                    <p>
                      Our expertise in CSS3 and JS helps us to create responsive
                      HTML5 web apps for small, medium {"&"} large scale
                      businesses.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d2.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Responsive App Design</p>
                    <p>
                      Our designers work in sync with you to deliver responsive UI
                      having every detail expected from the user's perspective.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d3.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>PSD to HTML5</p>
                    <p>
                      Convert your PSD files into HTML5 web pages with quality
                      coding adhered to industry standards with engaging UI
                      designs.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d4.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>HTML5 Migration</p>
                    <p>
                      Switch your existing website without compromising your data
                      and security using our HTML5 migration services.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d5.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Post-Launch Support</p>
                    <p>
                      We offer extended support and maintenance along with
                      upgrading your HTML5 web solution, whenever it's needed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HtmlDev;
