import { Link } from "react-router-dom";
import "./about.scss";
import "../Portfolio/portfolio.scss";
import "../Home/home.scss";
import AccordionComponent from "./Accordion";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";

const accordionData = [
  {
    title: 'How can we help your business?',
    content: "Through the collaboration with customers in discussing needs and demand, we're able to attain mutual understanding, gain customer trust to offer appropriate advice, and bring about suggestions on suitable technology to transform your business."
  },
  {
    title: 'What are the advantages of AsiaSoft?',
    content: 'Lorem Ipsum'
  },
  {
    title: 'How working process is simplified?',
    content: 'Lorem Ipsum',
  },
]

const About = () => {

  return (
    <Fragment>
      <Helmet>
        <title>About</title>
        <meta name="description" content="About" />
      </Helmet>
      <div>
        <div className={`about-ui`}>
          <div className={`main-banner`} style={{ backgroundImage: `url("assets/imgs/page-bg.png")` }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-7 col-sm-12 pe-4">
                  <div className={`left-side-info`}>
                    <h1 className="mb-3">
                      Our Treasure Comprises Our People, Vision {"&"} Values
                    </h1>
                    <p className="pe-md-5 me-lg-5">
                      Asia Software Solutions is not only a globally recognized IT
                      company but also a family filled with talented experts that
                      help global brands, enterprises, mid-size businesses or even
                      startups with innovative solutions.
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12 mt-md-0 mt-4">
                  <img
                    src="assets/imgs/about-asset-img.png"
                    className="img-width"
                    alt="Asia Software Solutions"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`service-ui custom-padding`}>
            <div className="container">
              <div className={`comapny-info`}>
                <span className={`small-font-head`}>OUR COMPANY</span>
                <p className="my-3">
                  <span>We run all kinds of IT services</span>{" "}
                  <span>that vow your success</span>
                </p>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-12">
                  <div className={`red-box`}>
                    <div>
                      <p className={`big-fonts mb-0`}>10+</p>
                      <p className={`light-fonts mb-4`}>
                        YEARS' EXPERIENCE IN IT
                      </p>
                      <p className="mb-0">
                        We’ve been triumphing all these 10 years. Sacrifices are
                        made up with success.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  {/* <!--About Accordions--> */}
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6"></div>
                      <div className="accordion my-5" id="company-qa-accordion">
                        {accordionData.map((item, idx) => {
                          return (
                            <AccordionComponent title={item.title} content={item.content} key={idx} />
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p className="company-bottom-p mt-lg-4 mb-0">
                <b>
                  Challenges are just opportunities in disguise.{" "}
                  <Link to="/contact">Take the challenge!</Link>
                </b>
              </p>
            </div>
          </div>

          <div className={`main-banner my-5`} style={{ height: "auto" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 col-sm-12">
                  <p className={`solution-h mb-4 pb-3`}>
                    <span>We excel in delivering</span>{" "}
                    <span>optimal solutions.</span>
                  </p>
                  <div className={`row row-equal-h`}>
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className={`main-box`}>
                        <img
                          src="assets/imgs/idea.png"
                          alt="Asia Software Solutions"
                        />
                        <p className={`main-h`}>Discover</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className={`main-box`}>
                        <img
                          src="assets/imgs/laptop.png"
                          alt="Asia Software Solutions"
                        />
                        <p className={`main-h`}>Design</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className={`main-box`}>
                        <img
                          src="assets/imgs/solar-system.png"
                          alt="Asia Software Solutions"
                        />
                        <p className={`main-h`}>Build</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className={`main-box`}>
                        <img
                          src="assets/imgs/diagram.png"
                          alt="Asia Software Solutions"
                        />
                        <p className={`main-h`}>Deliver</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1 col-sm-12"></div>
                <div className="col-md-6 col-sm-12">
                  <img
                    src="assets/imgs/solutions.png"
                    alt="Asia Software Solutions"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`home-success-numbers custom-padding pb-4`}>
            <div className={`container`}>
              <div className="row align-items-center">
                <div className="col-md-3 col-sm-6">
                  <div className={`inner-div`}>
                    <div>
                      <div className={`sec-top-img`}>
                        <img
                          src="assets/imgs/plumber.png"
                          alt="Asia Software Solutions"
                        />
                      </div>
                      <div className={`count`}>
                        <h3 className={`number`}>2,059+</h3>
                        <p>ACTIVE CLIENTS</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className={`inner-div`}>
                    <div>
                      <div className={`sec-top-img`}>
                        <img
                          src="assets/imgs/bar-chart.png"
                          alt="Asia Software Solutions"
                        />
                      </div>
                      <div className={`count`}>
                        <h3 className={`number`}>99.99</h3>
                        <p>SUCCESS RATE</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className={`inner-div`}>
                    <div>
                      <div className={`sec-top-img`}>
                        <img
                          src="assets/imgs/laptop.png"
                          alt="Asia Software Solutions"
                        />
                      </div>
                      <div className={`count`}>
                        <h3 className={`number`}>550+</h3>
                        <p>PROJECT DONE</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className={`inner-div border-0`}>
                    <div>
                      <div className={`sec-top-img`}>
                        <img
                          src="assets/imgs/medal.png"
                          alt="Asia Software Solutions"
                        />
                      </div>
                      <div className={`count`}>
                        <h3 className={`number`}>35+</h3>
                        <p>AWARDS</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`main-banner my-5`}
            style={{ height: "auto", background: "#f8f8f8" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-12">
                  <div className={`left-side-info`}>
                    <span className={`small-font-head`}>RESOURCES</span>
                    <p className={`brochure-h mt-3 mb-4`}>
                      <span>Get a copy of </span> <span>brochure on</span>{" "}
                      <span>Brand New IT Tech.</span>
                    </p>
                    <button type="button" className={`btn what-we-do-btn`}>
                      COMING SOON
                    </button>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <img
                    src="assets/imgs/brochure.png"
                    alt="Asia Software Solutions"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`customers mt-5`} style={{ height: "auto" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-6 col-sm-12">
                  <img
                    src="assets/imgs/customers.png"
                    alt="Asia Software Solutions"
                    className="w-100"
                  />
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12 offset-lg-1">
                  <div className={`ratings-info`}>
                    <h3>4.9/5.0</h3>
                    <div className="my-4">
                      <span>
                        <i className="fa fa-star me-1" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star me-1" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star me-1" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star me-1" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star me-1" aria-hidden="true"></i>
                      </span>
                    </div>
                    <p>by 700+ customers for 3200+ clients</p>
                  </div>
                  <div className={`ps-4 company-contacts`}>
                    <div className={`d-flex address mt-4`}>
                      <div className={`contact-div`}>
                        <img
                          src="assets/imgs/mail-fill.svg"
                          alt="Asia Software Solutions"
                          className={`address-icon`}
                        />
                      </div>
                      <p className={`contact-items ms-3`}>
                        <span className={`small-font-span`}> SAY HELLO</span>
                        <span>
                          <b>info@asiasoftwaresolutions.com</b>
                        </span>
                      </p>
                    </div>

                    <div className={`address d-flex address mt-4`}>
                      <div className={`contact-div`}>
                        <img
                          src="assets/imgs/phone.svg"
                          alt="Asia Software Solutions"
                          className={`address-icon`}
                        />
                      </div>
                      <p className={`contact-items ms-3`}>
                        <span className={`small-font-span`}>
                          CALL FOR ADIVCE NOW
                        </span>
                        <span className="text-nowrap">
                          <b> +(0172) 429 3317</b> / <b> +91 76268-68507</b>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
