import "./styles.scss"

const SendMsgModal = ({ open, closeModal }: any) => {
  return (
    <div className={`modal fade ${open ? 'show' : ''}`} id="send-msg-modal" style={{ display: open && "block" }}>
      {open && (
        <div className="modal-backdrop fade show"></div>
      )}
      <div className="modal-dialog mt-0" style={{ zIndex: 99999 }}>
        <div className="modal-content border-0">
          <div className="modal-header border-0 py-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={() => closeModal()}
            >
              <img
                src="assets/imgs/cross.svg"
                alt="Asia Software Solutions"
              />
            </button>
          </div>

          <div className="modal-body">
            <p>Thank you for sending us a request</p>
            <h4 className="modal-title mb-1">What's next?</h4>
            <ul className="ps-3">
              <li>We've sent you a confirmation mail</li>
              <li>
                Our representative will contact you within 24 hours (except
                weekends)
              </li>
              <li>
                If you have more info to share,reply to the confirmation
                email you've received.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendMsgModal;