import "./portfolio.scss";
import "../Home/home.scss";
import { Fragment, useState } from "react";
import { Helmet } from "react-helmet-async";

interface tabs {
  id: number;
  category: string;
}
const projectsTabs = [
  {
    techName: "Web",
  },
  {
    techName: "Mobile",
  },
  {
    techName: "Database",
  },
  {
    techName: "CMS",
  },
  {
    techName: "DevOps",
  },
];

const projects = [
  {
    imageUrl: "assets/imgs/bst-img.png",
    techName: "SASS APPLICATION",
    title: "Batch Skip Tracing",
    category: "Web",
    description:
      "It is Skip Tracing Solution, which is one of the few skip tracing companies to provide premium quality data",
    languages: ["MongoDB", "NODE", "ANGULAR"],
  },
  {
    imageUrl: "assets/imgs/portfolio-tech.jpg",
    techName: "SASS APPLICATION",
    title: "Batch Skip Tracing",
    category: "Web",
    description:
      "It is Skip Tracing Solution, which is one of the few skip tracing companies to provide premium quality data",
    languages: ["MongoDB", "NODE", "ANGULAR"],
  },
  {
    imageUrl: "assets/imgs/portfolio-paramount.jpg",
    techName: "SASS APPLICATION",
    title: "Batch Skip Tracing",
    category: "Web",
    description:
      "It is Skip Tracing Solution, which is one of the few skip tracing companies to provide premium quality data",
    languages: ["MongoDB", "NODE", "ANGULAR"],
  },
  {
    imageUrl: "assets/imgs/chatbot.png",
    techName: "SASS APPLICATION",
    title: "Batch Skip Tracing",
    category: "Web",
    description:
      "It is Skip Tracing Solution, which is one of the few skip tracing companies to provide premium quality data",
    languages: ["MongoDB", "NODE", "ANGULAR"],
  },
  {
    imageUrl: "assets/imgs/bdamx.png",
    techName: "SASS APPLICATION",
    title: "Batch Skip Tracing",
    category: "Web",
    description:
      "It is Skip Tracing Solution, which is one of the few skip tracing companies to provide premium quality data",
    languages: ["MongoDB", "NODE", "ANGULAR"],
  },
  {
    imageUrl: "assets/imgs/webcastcloud.png",
    techName: "SASS APPLICATION",
    title: "Batch Skip Tracing",
    category: "Web",
    description:
      "It is Skip Tracing Solution, which is one of the few skip tracing companies to provide premium quality data",
    languages: ["MongoDB", "NODE", "ANGULAR"],
  },
  {
    imageUrl: "assets/imgs/portfolio-sec.jpg",
    techName: "SASS APPLICATION",
    title: "Batch Skip Tracing",
    category: "Web",
    description:
      "It is Skip Tracing Solution, which is one of the few skip tracing companies to provide premium quality data",
    languages: ["MongoDB", "NODE", "ANGULAR"],
  },
  {
    imageUrl: "assets/imgs/portfolio-smarty.jpg",
    techName: "SASS APPLICATION",
    title: "Batch Skip Tracing",
    category: "Web",
    description:
      "It is Skip Tracing Solution, which is one of the few skip tracing companies to provide premium quality data",
    languages: ["MongoDB", "NODE", "ANGULAR"],
  },
  {
    imageUrl: "assets/imgs/fitness-tracker.png",
    techName: "SASS APPLICATION",
    title: "Batch Skip Tracing",
    category: "Mobile",
    description:
      "It is Skip Tracing Solution, which is one of the few skip tracing companies to provide premium quality data",
    languages: ["MongoDB", "NODE", "ANGULAR"],
  },
];

const Portfolio = () => {
  const [activeTechnologyTab, setActiveTechnologyTab] = useState<tabs>({
    id: 0,
    category: "Web",
  });

  const handleTabs = (id: number, category: string) => {
    setActiveTechnologyTab({ id, category });
  };
  return (
    <Fragment>
      <Helmet>
        <title>Portfolio</title>
        <meta name="description" content="Portfolio" />
      </Helmet>
      <div className={`portfolio-ui`}>
        <div className={`main-banner`} style={{ backgroundImage: `url("assets/imgs/page-bg.png")` }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <div className={`left-side-info`}>
                  <h1>Projects that speak about our diversity</h1>
                  <p>
                    We’re proud about what we do! Our portfolio is a sheer
                    presentation about years of industry experience,
                    collaboration, expertise, and quality deliverables.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img
                  src="assets/imgs/portfolio.png"
                  className="w-100"
                  alt="Asia Software Solutions"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`technologies`}>
          <div className="container">
            <div>
              <div className="card mt-3 tab-card border-0">
                <div className={`card-header tab-card-header py-0 px-4`}>
                  <ul
                    className={`nav nav-tabs card-header-tabs mt-3 mb-2`}
                    id="myTab"
                    role="tablist"
                  >
                    {projectsTabs.map((tab, idx) => {
                      return (
                        <li className="nav-item" key={idx}>
                          <a
                            className={`nav-link ${activeTechnologyTab.id === idx ? 'active-navlink' : ''}`}
                            id="mobile-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="Mobile"
                            aria-selected="true"
                            onClick={() => handleTabs(idx, tab.techName)}
                          >
                            {tab.techName}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>

                <div className="tab-content" id="tabContent">
                  <div
                    className="tab-pane fade show active p-md-3"
                    id="mobile"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                  >
                    <div className={`mobile-tab-ui`}>
                      <div className={`our-projects`}>
                        <div className="container">
                          <div className={`row row-equal-h`}>
                            {
                              projects.filter((project) => project.category === activeTechnologyTab.category).map((item, idx) => {
                                return (
                                  <div className="col-md-6 col-lg-4 col-xl-4 col-sm-12 mt-md-5 mt-4">
                                    <div>
                                      <img
                                        src={item.imageUrl}
                                        alt="Asia Software Solutions"
                                      />
                                      <p className={`app-name`}>{item.techName.toUpperCase()}</p>
                                      <h6 className={`main-h`}>{item.title}</h6>
                                      <p>{item.description}</p>
                                      {item.languages.map((lang, index) => {
                                        return (
                                          <button
                                            type="button"
                                            className={`btn btn-outline-secondary mr-2`}
                                            key={index}
                                          >
                                            {lang.toUpperCase()}
                                          </button>
                                        )
                                      })}
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div >
                        </div >
                      </div >
                    </div >
                  </div >
                  <div
                    className="tab-pane fade p-3"
                    id="front-end"
                    role="tabpanel"
                    aria-labelledby="front-end-tab"
                  >
                    <h5 className="card-title">Tab Card Two</h5>
                  </div>
                  <div
                    className="tab-pane fade p-3"
                    id="database"
                    role="tabpanel"
                    aria-labelledby="database-tab"
                  >
                    <h5 className="card-title">Tab Card Three</h5>
                  </div>
                  <div
                    className="tab-pane fade p-3"
                    id="backend"
                    role="tabpanel"
                    aria-labelledby="backend-tab"
                  >
                    <h5 className="card-title">Tab Card four</h5>
                  </div>
                  <div
                    className="tab-pane fade p-3"
                    id="cms"
                    role="tabpanel"
                    aria-labelledby="cms-tab"
                  >
                    <h5 className="card-title">Tab Card five</h5>
                  </div>
                  <div
                    className="tab-pane fade p-3"
                    id="devops"
                    role="tabpanel"
                    aria-labelledby="devops-tab"
                  >
                    <h5 className="card-title">Tab Card six</h5>
                  </div>
                </div >
              </div >
            </div >
          </div >
        </div >

        <div
          className={`main-banner h-auto`}
          style={{
            backgroundColor: "#eef6ff",
            marginTop: "3rem",
            padding: "4rem 0",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <div className={`left-side-info`}>
                  <h1 className="mb-4">Lets Discuss the Requirements</h1>
                  <p className={`requirement-info`}>
                    We’re proud about what we do! Our portfolio is a sheer
                    presentation about years of industry experience,
                    collaboration, expertise, and quality deliverables.
                  </p>
                  <div className={`company-contacts`}>
                    <div className={`address`}>
                      <div className={`contact-div`}>
                        <img
                          src="assets/imgs/mail-fill.svg"
                          alt="Asia Software Solutions"
                          className={`address-icon`}
                        />
                      </div>
                      <p>
                        <span>
                          {" "}
                          <b>Email:</b>
                        </span>
                        <span> info@asiasoftwaresolutions.com</span>
                      </p>
                    </div>

                    <div className={`address`}>
                      <div className={`contact-div`}>
                        <img
                          src="assets/imgs/phone.svg"
                          alt="Asia Software Solutions"
                          className={`address-icon`}
                        />
                      </div>
                      <p>
                        <span>
                          <b>Contact:</b>
                        </span>
                        <span> +(0172) 429 3317 / +91 76268-68507</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <form className={`portfolio-form mt-md-0 mt-4`}>
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control`}
                      id="name"
                      placeholder="Your Full Name"
                      name="name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className={`form-control`}
                      id="email"
                      placeholder="Email Address"
                      name="email"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control`}
                      id="phn-number"
                      placeholder="Phone Number"
                      name="phn-number"
                    />
                  </div>

                  <div className="form-group">
                    <textarea
                      className={`form-control h-auto`}
                      placeholder="Message"
                      rows={4}
                      name="message"
                    />
                  </div>

                  <button type="submit" className={`btn msg-btn mt-3`}>
                    MESSAGE
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Portfolio;
