import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import "../Home/home.scss";

const AngularJSDeveloper = () => {
  return (
    <Fragment>
      <Helmet>
        <title>AngularJS Developers</title>
        <meta name="description" content="AngularJS Developers" />
      </Helmet>
      <div>
        <div className={`hire-ui`}>
          <div className={`main-banner`} style={{ backgroundImage: `url("../../../assets/imgs/page-bg.png")` }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-7 col-sm-12 pe-4">
                  <div className={`left-side-info`}>
                    <h1 className="mb-3">
                      We Develop Powerful Front-end with AngularJS
                    </h1>
                    <p className="pe-md-5 me-lg-5">
                      Hire expert AngularJS developers and programmers in India to
                      work exclusively on your AngularJS application or web
                      development project from Asia Software Solutions. Unleash
                      the power of Javascript and design a highly scalable,
                      single-page application across platforms when you outsource
                      AngularJs developers from Asia Software Solutions.
                    </p>
                  </div>
                </div>

                <div className="col-md-5 col-sm-12 mt-md-0 mt-4">
                  <img
                    src="../../../assets/imgs/about-asset-img.png"
                    className="img-width"
                    alt="Asia Software Solutions"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`dev-process custom-padding`}>
            <div className="container">
              <div className="text-center">
                <span className="small-font-head">WHY DO PEOPLE CHOOSE US?</span>
                <h5 className={`section-heading mt-4 d-grid`}>
                  <span>Advantages of Hiring AngularJS Developers</span>{" "}
                  <span>@asiasoftsol</span>
                </h5>
              </div>
              <div className={`row row-equal-h`}>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Custom AngularJS Development</p>
                    <p>
                      Creating high impact applications in different open source frameworks like Ionic,
                      React, Mean Stack,Protactor {"&"} Radion.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d1.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Hybrid apps</p>
                    <p>
                      Create a multiplatform app for your innovative ideas with
                      integrated elements of AngularJS native and web
                      applications.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d2.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>API/ Backend Development</p>
                    <p>
                      Effortless database driven Angular apps with tightly
                      integrated backend system through APIs.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d3.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Ui/Ux design</p>
                    <p>
                      Along with innovate UI get compelling and functional UX
                      design to transform your ideas into meaningful user stories.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d4.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Version Migration</p>
                    <p>
                      Our AngularJS expertise helps you easily upgrade your
                      current AngularJS application to an advanced version.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d5.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Support {"&"} Maintenance</p>
                    <p>
                      We provide useful AngularJS consulting based on your
                      personalised business requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AngularJSDeveloper;
