import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiService } from "../../utilities/api.service";
import { Helmet } from "react-helmet-async"
import "../Home/home.scss";
import "./careers.scss";

interface careerData {
  jobId: number
  jobTitle: string
  numberofOpenings: number
}

const Careers = () => {
  const [careerData, setCareerData] = useState<careerData[]>([]);

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData() {
    ApiService.getCareersData("opening/get-all").then((data: any) => {
      setCareerData(data)
    })
  }

  return (
    <Fragment>
      <Helmet>
        <title>Careers</title>
        <meta name="description" content="Careers" />
      </Helmet>
      <div>
        <div className={`careers-ui`}>
          <div className={`main-banner`}>
            <div className="container">
              <div className="page-header-content">
                <div className={`left-side-info`}>
                  <h1 className="mb-md-5 mb-3">
                    Become a Part of Asia Software Solutions Family
                  </h1>
                  <p className="pe-md-5 me-lg-5">
                    We’re always looking for passionate and talented people to
                    join our team. Joining us could be your life changing decision
                    in doing so with endless goodies on the way. Teamwork ,
                    passion and strive to excel have been the pillars of our
                    growth & achievements are vital to our future success. It is a
                    home away from home, where we work as a team for a common goal
                    - to evolve constantly with the latest technologies while
                    taking utmost care of our most valuable assets, our employees.
                    Experience the ‘Feel Good factor’ and the value of ‘Work-life
                    balance’ by working with us.
                  </p>
                  <h3>We hire character. Train skill.</h3>
                  <span>– Peter Schutz</span>
                </div>
              </div>
            </div>
          </div>

          <section className="career-items-ui py-md-5 py-4">
            <div className="container">
              <div className="careers mt-4">
                <div>
                  <h2>
                    <b>Come and Grow with us!</b>
                  </h2>
                  <p className="my-4">
                    Do you want to work on the newest and cutting edge
                    technologies which in a few years will be more or less the
                    industry standards? Look no further. We might have the ideal
                    job you've been looking for. Join us and give yourself an
                    opportunity to take your career to the newest heights.
                  </p>
                </div>
              </div>

              <div className="positions text-center mb-5">
                <div className="mb-3">
                  <h2 className="mb-4 mt-5">
                    <b>Open Positions</b>
                  </h2>
                </div>
                {(careerData && careerData.length) && (
                  careerData.map((item: careerData, idx) => {
                    return (
                      <Link
                        className="nav-link position-link"
                        to={{
                          pathname: "/career-detail",
                          state: item.jobId,
                        }}
                        key={idx}
                      >
                        <h4>{item.jobTitle}</h4>
                        <span>
                          {`${item.numberofOpenings} Positions`}<i className="fa fa-long-arrow-right ms-4"></i>
                        </span>
                      </Link>
                    )
                  })
                )}
              </div>
            </div>
          </section>

          <div
            className={`main-banner py-5 mt-0 blue-bg`}
            style={{ height: "auto" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="mx-auto text-center">
                  <div className={`left-side-info`}>
                    <p className="text-white mb-0">
                      Don't see a job you are interested in?
                    </p>
                    <p className="text-white">
                      Reach out to us to explore options. We are always happy to
                      connect with talented people.
                    </p>
                    <span className="snd-resume text-white">
                      Send your resume on{" "}
                      <Link to="/">
                        <b>hr@asiasoftwaresolutions.com</b>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`dev-process custom-padding`}>
            <div className="container">
              <div className="text-center">
                <h5 className={`section-heading d-grid`}>
                  Perks of Joining Asia Software Solutions
                </h5>
              </div>
              <div className={`row row-equal-h`}>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="assets/imgs/hire-d.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>5 Days a Week</p>
                    <p>
                      All weekends off to promote work-life balance and mental
                      peace.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="assets/imgs/hire-d1.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Flexible Timings</p>
                    <p>
                      To empower employees to manage things in a fast-paced life.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="assets/imgs/hire-d2.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Work From Home</p>
                    <p>
                      Flexibility to allow employees to take personal time with
                      work.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="assets/imgs/hire-d3.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Training</p>
                    <p>
                      Practical training {"&"} learning from the experts and
                      mentors.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="assets/imgs/hire-d4.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Rewards</p>
                    <p>
                      To value and appreciate the performance on a regular basis.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="assets/imgs/hire-d5.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Team Building</p>
                    <p>
                      Indoor {"&"} outdoor team building workshops and activities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Careers;
