import { Link } from "react-router-dom";
import SideModal from "../Modals/SideModal";
import "./footer.scss";


const Footer = () => {
  return (
    <div>
    <div className={`bottom-section custom-padding py-0`}>
      <footer className={`footer`}>
        <div className={`container pt-5 border-b`}>
          <div className="row">
            <div className="col-lg-8 col-xl-9 col-md-6 col-sm-12 col-12">
              <div className="row">
                <div className="col-xl-2 col-lg-4 col-5">
                  <h6>Quick Links</h6>
                  <ul className={`pl-0 type-none`}>
                    <li>
                      <Link className="nav-link" to="/about">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/careers">
                        Careers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        How to Engage
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        We Work With
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        Testimonilas
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        Policies
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        Sitemap
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-8 col-xl-9 col-sm-12 col-12 order-three small-scr-display">
                  <div className="row">
                    <div className="col-6 pl-md-4">
                      <h6>Services</h6>
                      <ul className={`pl-0 type-none`}>
                        <li>
                          <Link className="nav-link" to="/">
                            Android App Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/services/angulardev">
                            Angular App Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            Argumented Reality
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            AWS Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            BigCommerce
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            CakePHP Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            Codelgniter Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            Cross-platform Apps
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="nav-link"
                            to="/services/.netdevelopers"
                          >
                            .Net Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            Drupal Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            DynamoDB Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            Firebase Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            Google Cloud
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            ios App Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            Java Development
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <h6 className="invisible">Quick Links</h6>
                      <ul className={`pl-0 type-none`}>
                        <li>
                          <Link className="nav-link" to="/">
                            MongoDB Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            MySQL App Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            NodeJS Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            OpenCart Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            Oracle Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            OsCommerce
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            PHP Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            PWA Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            Python Development
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="nav-link"
                            to="/services/reactnativedevelopers"
                          >
                            React Native
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="nav-link"
                            to="/services/reactdevelopers"
                          >
                            ReactJS Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            Shopify Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            VueJS Development
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            WooCommerce
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="/">
                            Wordpress Development
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 col-7">
                  <h6>Hire Developer Team</h6>
                  <ul className={`pl-0 type-none`}>
                    <li>
                      <Link className="nav-link" to="/services/reactdevelopers">
                        ReactJS Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        NodeJS web Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        VueJS Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        AWS Development
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        PHP Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        Magento Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        Wordpress Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        Android App Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        iOS Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        Flutter App Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-link"
                        to="/services/reactnativedevelopers"
                      >
                        React Native App Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        Python Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/">
                        Java Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/services/.netdevelopers">
                        ASP.NET Developers
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/services/htmldev">
                        HTML5 Developers
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-3 col-md-6 col-sm-12 col-12 px-md-1">
              <h6>Contact Us</h6>
              <div className={`company-contacts`}>
                <div className={`address`}>
                  <img
                    src="../../../assets/imgs/mail-fill.svg"
                    alt="Asia Software Solutions"
                    className={`address-icon`}
                  />
                  <p>
                    <span className={`contact-h`}>Email:</span>
                    <span> info@asiasoftwaresolutions.com</span>
                  </p>
                </div>

                <div className={`address`}>
                  <img
                    src="../../../assets/imgs/phone.svg"
                    alt="Asia Software Solutions"
                    className={`address-icon`}
                  />
                  <p>
                    <span className={`contact-h`}>Contact:</span>
                    <span> +(0172) 429 3317 / +91 76268-68507</span>
                  </p>
                </div>

                <div className={`address`}>
                  <img
                    src="../../../assets/imgs/calender.svg"
                    alt="Asia Software Solutions"
                    className={`address-icon`}
                  />
                  <p>
                    <span className={`contact-h`}>Working Days:</span>
                    <span> Monday to Friday</span>
                  </p>
                </div>

                <div className={`address`}>
                  <img
                    src="../../../assets/imgs/calender.svg"
                    alt="Asia Software Solutions"
                    className={`address-icon`}
                  />
                  <p>
                    <span className={`contact-h`}>Working Hours:</span>
                    <span> 9:30 AM to 6:30 PM</span>
                  </p>
                </div>

                <div className={`address`}>
                  <img
                    src="../../../assets/imgs/location.svg"
                    alt="Asia Software Solutions"
                    className={`address-icon location position-relative h-100`}
                  />
                  <p>
                    <span className="company-address">
                      <span>
                        <b>Address:</b> F-247 Second floor 8B Industrial Area,
                      </span>{" "}
                      <span>Sector 74, Sahibzada Ajit Singh Nagar,</span> Punjab
                      140308.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <details className="small-screen-content order-three">
              <summary className="d-flex align-items-center">
                <h6>
                  Services <i className="fa fa-plus" aria-hidden="true"></i>
                </h6>
              </summary>
              <div className="col-lg-8 col-xl-9 col-sm-12 col-12">
                <div className="row">
                  <div className="col-6 pl-md-4">
                    <ul className={`pl-0 type-none`}>
                      <li>
                        <Link className="nav-link" to="/">
                          Android App Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/services/angulardev">
                          Angular App Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          Argumented Reality
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          AWS Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          BigCommerce
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          CakePHP Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          Codelgniter Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          Cross-platform Apps
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link"
                          to="/services/.netdevelopers"
                        >
                          .Net Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          Drupal Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          DynamoDB Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          Firebase Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          Google Cloud
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          ios App Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          Java Development
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className={`pl-0 type-none`}>
                      <li>
                        <Link className="nav-link" to="/">
                          MongoDB Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          MySQL App Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          NodeJS Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          OpenCart Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          Oracle Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          OsCommerce
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          PHP Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          PWA Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          Python Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link"
                          to="/services/reactnativedevelopers"
                        >
                          React Native
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link"
                          to="/services/reactdevelopers"
                        >
                          ReactJS Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          Shopify Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          VueJS Development
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          WooCommerce
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/">
                          Wordpress Development
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </details>
          </div>
        </div>
        <div className={`container py-4 px-0 copyright-text`}>
          <div className={`text-center w-100 m-0 px-2`}>
            <div className="mb-1 mobile-scr-space">
              2021 {"&"} Asia Software Solutions. All Rights Reserved
            </div>
            {/* <div className="d-md-flex align-items-center mb-1">
              <span>Terms of Use</span>
              <span>Privacy Policy</span>
              <span className="border-0">Disclaimer</span>
            </div> */}
          </div>
        </div>
      </footer>
    </div>    
    </div>
  );
};

export default Footer;
