import { Fragment, useState } from "react";
import { Helmet } from "react-helmet-async";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./home.scss";

const tabsData = [
  {
    techName: "Mobile",
  },
  {
    techName: "Backend",
  },
  {
    techName: "Frontend",
  },
  {
    techName: "Database",
  },
  {
    techName: "CMS",
  },
  {
    techName: "DevOps/Cloud",
  },
]

const technologies = [
  {
    techName: "iOS",
    category: "Mobile",
    imageURL: "assets/imgs/ios.svg"
  },
  {
    techName: "Android",
    category: "Mobile",
    imageURL: "assets/imgs/android.svg"
  },
  {
    techName: "React Native",
    category: "Mobile",
    imageURL: "assets/imgs/react.svg"
  },
  {
    techName: "Flutter",
    category: "Mobile",
    imageURL: "assets/imgs/flutter.svg"
  },
  {
    techName: "Titanium",
    category: "Mobile",
    imageURL: "assets/imgs/titanium.svg"
  },
  {
    techName: "Ionic",
    category: "Mobile",
    imageURL: "assets/imgs/ionic.svg"
  },
  {
    techName: "Swift",
    category: "Mobile",
    imageURL: "assets/imgs/Swift.svg"

  },
  {
    techName: "Kotlin",
    category: "Mobile",
    imageURL: "assets/imgs/kotlin.svg"
  },
  {
    techName: "ObjectiveC",
    category: "Mobile",
    imageURL: "assets/imgs/ObjectiveC.svg"
  },
  {
    techName: "Xamarin",
    category: "Mobile",
    imageURL: "assets/imgs/xamarin.svg"
  },
  {
    techName: ".NET",
    category: "Backend",
    imageURL: "assets/imgs/dotnet.svg"
  },
  {
    techName: "NodeJS",
    category: "Backend",
    imageURL: "assets/imgs/Node.svg"
  },
  {
    techName: "Typescript",
    category: "Backend",
    imageURL: "assets/imgs/TypeScript.svg"
  },
  {
    techName: "Angular",
    category: "Frontend",
    imageURL: "assets/imgs/Angular.svg"
  },
  {
    techName: "React",
    category: "Frontend",
    imageURL: "assets/imgs/react.svg"
  },
  {
    techName: "Html 5",
    category: "Frontend",
    imageURL: "assets/imgs/HTML5.svg"
  },
  {
    techName: "Dynamo DB",
    category: "Database",
    imageURL: "assets/imgs/DynamoDB.svg"
  },
  {
    techName: "Mongo DB",
    category: "Database",
    imageURL: "assets/imgs/MongoDB.svg"
  },
  {
    techName: "MsSQL",
    category: "Database",
    imageURL: "assets/imgs/MsSQL.svg"
  },
  {
    techName: "MySQL",
    category: "Database",
    imageURL: "assets/imgs/MySQL.svg"
  },
  {
    techName: "PostgreSQL",
    category: "Database",
    imageURL: "assets/imgs/PostgreSQL.svg"
  },
  {
    techName: "Oracle",
    category: "Database",
    imageURL: "assets/imgs/Oracle.svg"
  },
  {
    techName: "Joomla",
    category: "CMS",
    imageURL: "assets/imgs/Joomla.svg"
  },
  {
    techName: "AWS",
    category: "DevOps/Cloud",
    imageURL: "assets/imgs/AWS.svg"
  },
  {
    techName: "Azure",
    category: "DevOps/Cloud",
    imageURL: "assets/imgs/Azure.svg"
  },
  {
    techName: "Google cloud",
    category: "DevOps/Cloud",
    imageURL: "assets/imgs/GoogleCloud.svg"
  },
]

const testimonialData = [
  {
    feedback: "Asia Software Solutions is dependable and responsive to client’s request. Always easy and convenient to communicate with them for any issues and support. Gladly recommend them to anyone who wants to build ideas into real products.",
    name: "Alejandro Castro",
    position: ""
  },
  {
    feedback: "&quot;Asia Software Solutions is dependable and responsive to client’s request. Always easy and convenient to communicate with them for any issues and support. Gladly recommend them to anyone who wants to build ideas into real products.&quot;",
    name: "Alejandro Castro2",
    position: "Co-Founder, Company Name"
  },
  {
    feedback: "&quot;Asia Software Solutions is dependable and responsive to client’s request. Always easy and convenient to communicate with them for any issues and support. Gladly recommend them to anyone who wants to build ideas into real products.&quot;",
    name: "Alejandro Castro3",
    position: "Co-Founder, Company Name"
  }
]

const servicesData = [
  {
    serviceName: "Hire Dedicated Developers",
    content: "Create your own team of developers for your software development project on short term, long term or permanent basis with guaranteed project delivery at affordable prices.",
    imageUrl: "assets/imgs/m-app-dev.png",
  },
  {
    serviceName: "Mobile App Development",
    content: "We build intuitive and engaging Android, iOS and cross-platform apps for businesses, consumers and enterprises that end users love and adapt to them very quickly.",
    imageUrl: "assets/imgs/m-app-dev.png",
  },
  {
    serviceName: "Custom Software Solutions",
    content: "Our team do depth research of requirements and have wide range of proficiency in creating and deploying customized apps, for clients worldwide, executing the latest tools & technologies.",
    imageUrl: "assets/imgs/desktop-app.png",
  },
  {
    serviceName: "Web Development",
    content: "Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.",
    imageUrl: "assets/imgs/web-dev.png",
  },
  {
    serviceName: "UI/UX Design & Research",
    content: "Starting from concept, information architecture, visual identity and UI/UX design, our team delivers dazzling experiences for maximum user engagement.",
    imageUrl: "assets/imgs/ui-ux.png",
  },
  {
    serviceName: "QA Services",
    content: "Our QA engineers don’t just test, they make your software application successful ensuring quality delivery with expert manual and automated testing services.",
    imageUrl: "assets/imgs/desktop-app.png",
  },
]

interface tabs {
  id: number,
  category: string,
}

const Home = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activeTechnologyTab, setActiveTechnologyTab] = useState<tabs>({ id: 0, category: "Mobile" });

  const handleTabs = (id: number, category: string) => {
    setActiveTechnologyTab({ id, category })
  }

  const handleNextSlide = () => {
    setActiveIndex(activeIndex === testimonialData.length - 1 ? 0 : activeIndex + 1)
  }

  const handlePrevSlide = () => {
    setActiveIndex(activeIndex === 0 ? testimonialData.length - 1 : activeIndex - 1)
  }
  return (
    <Fragment>
      <Helmet>
        <title>Asia Software Solutions</title>
        <meta name="description" content="Home" />
      </Helmet>
      <div className="home-ui">
        <div className={`main-banner`} style={{ backgroundImage: `url("assets/imgs/page-bg.png")` }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <div className={`left-side-info`}>
                  <h1 className="animate__animated animate__bounce">
                    Providing, Innovative {"&"} Technological Business Solutions.
                  </h1>
                  <p className="my-4">Take your Business to Next Level</p>
                  <a href="/#home-services"
                    type="button"
                    className={`btn  mb-3 mb-md-0 what-we-do-btn`}
                  >
                    WHAT WE DO
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 my-4">
                <img
                  src="assets/imgs/asset-1.png"
                  className="img-width"
                  alt="Asia Software Solutions"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`who-we-are custom-padding`}>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6 col-sm-12 mobile-scr-order">
                <div>
                  <img
                    src="assets/imgs/group-6.png"
                    className="img-width"
                    alt="Asia Software Solutions"
                  />
                </div>
              </div>
              <div className={`col-md-6 col-sm-12 ps-lg-0 right-side-def`}>
                <h6 className={`main-heading`}>
                  Hire the Best Web and Mobile App Developers and UI/UX Designers
                </h6>
                <p>
                  Hire web developers from Asia Software Solutions who have proven
                  expertise in trending web technologies like PHP, Laravel,
                  Magento, ReactJS, front-end technologies like AngularJS and
                  back-end technologies like Node.js to develop custom and
                  responsive websites, web apps and eCommerce solutions.
                </p>
                <p>
                  {" "}
                  Our Android app, iOS app and cross-platform mobile app
                  developers are adroit in the latest mobile technologies and
                  tools like Android Studio, Xcode, Kotlin, Xamarin, PhoneGap,
                  React Native, etc. to build next-gen mobile applications.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={`dev-process custom-padding`}>
          <div className="container">
            <h5 className={`section-heading`}>
              We Simplify Software Development Process
            </h5>
            <div className={`row row-equal-h`}>
              <div className="col-md-6 col-lg-3 col-xl-3 col-sm-12">
                <div className={`main-box`}>
                  <img src="assets/imgs/idea.png" alt="Asia Software Solutions" />
                  <p className={`main-h`}>Discover</p>
                  <p>
                    We shape brands through exploration, applying in-depth
                    research to challenge assumptions at every turn..
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 col-xl-3 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/laptop.png"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>Design</p>
                  <p>
                    Our design approach is to simplify. We embrace the joy in
                    creating something unique that is easy for end users.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 col-xl-3 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/solar-system.png"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>Build</p>
                  <p>
                    Using modern technologies, we build with efficiency and skill,
                    creating flexible and scalable business-driven solutions.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 col-xl-3 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/diagram.png"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>Deliver</p>
                  <p>
                    We take an iterative approach to both our work and our
                    practice, always looking for ways to improve what we do.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`dev-process core-service-bg custom-padding`} id="home-services">
          <div className="container-fluid px-md-5">
            <h5 className={`section-heading`}>Our Core Services</h5>
            <Carousel
              arrows={false}
              additionalTransfrom={0}
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1501 },
                  items: 5,
                  paritialVisibilityGutter: 70
                },
                laptop: {
                  breakpoint: { max: 1500, min: 1250 },
                  items: 4,
                  paritialVisibilityGutter: 60
                },
                tablet: {
                  breakpoint: { max: 1249, min: 969 },
                  items: 3,
                  paritialVisibilityGutter: 50
                },
                mini: {
                  breakpoint: { max: 968, min: 591 },
                  items: 2,
                  paritialVisibilityGutter: 40
                },
                mobile: {
                  breakpoint: { max: 590, min: 0 },
                  items: 1,
                  paritialVisibilityGutter: 30
                }
              }}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {servicesData.map((i, _i) => {
                return (
                  <div className={`main-box`} key={_i}>
                    <img
                      src={i.imageUrl}
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>{i.serviceName}</p>
                    <p>{i.content}</p>
                  </div>
                )
              })}
            </Carousel>
          </div >
        </div >

        <div className={`main-banner my-5`} style={{ height: "auto" }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <div className={`left-side-info`}>
                  <span className={`small-font-head`}>
                    Guaranteed Customer Satisfaction
                  </span>
                  <h1 className={`customer-h`}>We Promise. We Deliver.</h1>
                  <p>
                    Have an idea? Work with us and see how it goes. We promise to
                    go the extra mile for every project that we take onboard. We
                    love to speak, interact and understand the idea of new brands
                    in market..
                  </p>
                  <a href="/get-quote" type="button" className={`btn what-we-do-btn`}>
                    LET&apos;S WORK TOGETHER
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-5 mt-md-0">
                <img
                  src="assets/imgs/cutomer.png"
                  alt="Asia Software Solutions"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`home-success-numbers custom-padding`}>
          <div className={`container`}>
            <div className="row align-items-center">
              <div className="col-md-3 col-sm-6">
                <div className={`inner-div`}>
                  <div>
                    <div className={`sec-top-img`}>
                      <img
                        src="assets/imgs/plumber.png"
                        alt="Asia Software Solutions"
                      />
                    </div>
                    <div className={`count`}>
                      <h3 className={`number`}>2,059+</h3>
                      <p>ACTIVE CLIENTS</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className={`inner-div`}>
                  <div>
                    <div className={`sec-top-img`}>
                      <img
                        src="assets/imgs/bar-chart.png"
                        alt="Asia Software Solutions"
                      />
                    </div>
                    <div className={`count`}>
                      <h3 className={`number`}>99.99</h3>
                      <p>SUCCESS RATE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className={`inner-div`}>
                  <div>
                    <div className={`sec-top-img`}>
                      <img
                        src="assets/imgs/laptop.png"
                        alt="Asia Software Solutions"
                      />
                    </div>
                    <div className={`count`}>
                      <h3 className={`number`}>550+</h3>
                      <p>PROJECT DONE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className={`inner-div border-0`}>
                  <div>
                    <div className={`sec-top-img`}>
                      <img
                        src="assets/imgs/medal.png"
                        alt="Asia Software Solutions"
                      />
                    </div>
                    <div className={`count`}>
                      <h3 className={`number`}>35+</h3>
                      <p>AWARDS</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`technologies`}>
          <div className="container">
            <h5 className={`section-heading text-dark`}>
              Technologies we work with
            </h5>
            <div>
              <div className="card mt-3 tab-card border-0">
                <div className={`card-header tab-card-header py-0 px-4`}>
                  <ul
                    className={`nav nav-tabs card-header-tabs mt-3 mb-2`}
                    id="myTab"
                    role="tablist"
                  >
                    {tabsData.map((item, idx) => {
                      return (
                        <li className="nav-item" key={idx}>
                          <a
                            className={`nav-link ${activeTechnologyTab?.id === idx ? 'active-navlink' : ''}`}
                            id="mobile-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="Mobile"
                            aria-selected="true"
                            onClick={() => handleTabs(idx, item.techName)}
                          >
                            {item.techName}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>

                <div className="tab-content" id="tabContent">
                  <div
                    className="tab-pane fade show active p-md-3"
                    id="mobile"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                  >
                    <div className={`mobile-tab-ui pt-4`}>
                      <div className="container">
                        <div className="row justify-content-center">
                          {
                            technologies.filter((tech) => tech.category === activeTechnologyTab?.category).map((item, idx) => {
                              return (
                                <div className={`col col-sm-3 col-4 `} key={idx}>
                                  <img
                                    src={item.imageURL}
                                    alt="Asia Software Solutions"
                                  />
                                  <p className="text-center">{item.techName}</p>
                                </div>
                              )
                            })
                          }
                        </div >
                      </div >
                    </div>
                  </div>
                  <div
                    className="tab-pane fade p-3"
                    id="front-end"
                    role="tabpanel"
                    aria-labelledby="front-end-tab"
                  >
                    <div className={`mobile-tab-ui pt-4`}>
                      <div className="container">
                        <div className="row justify-content-center">
                          {
                            technologies.filter((tech) => tech.category === activeTechnologyTab?.category).map((item, idx) => {
                              return (
                                <div className={`col col-sm-3 col-4 `} key={idx}>
                                  <img
                                    src={item.imageURL}
                                    alt="Asia Software Solutions"
                                  />
                                  <p className="text-center">{item.techName}</p>
                                </div>
                              )
                            })
                          }
                        </div >
                      </div >
                    </div>
                  </div>
                  <div
                    className="tab-pane fade p-3"
                    id="database"
                    role="tabpanel"
                    aria-labelledby="database-tab"
                  >
                    <h5 className="card-title">Tab Card Three</h5>
                  </div>
                  <div
                    className="tab-pane fade p-3"
                    id="backend"
                    role="tabpanel"
                    aria-labelledby="backend-tab"
                  >
                    <h5 className="card-title">Tab Card four</h5>
                  </div>
                  <div
                    className="tab-pane fade p-3"
                    id="cms"
                    role="tabpanel"
                    aria-labelledby="cms-tab"
                  >
                    <h5 className="card-title">Tab Card five</h5>
                  </div>
                  <div
                    className="tab-pane fade p-3"
                    id="devops"
                    role="tabpanel"
                    aria-labelledby="devops-tab"
                  >
                    <h5 className="card-title">Tab Card six</h5>
                  </div>
                </div >
              </div >
            </div >
          </div >
        </div >

        <div className={`home-testimonials custom-padding`}>
          <div className="container py-lg-5 py-4">
            <div className="row align-items-center">
              <div className="col-md-4 col-sm-12 px-md-0">
                <div className="position-relative">
                  <div className={`testimonial-side-banner`}>
                    <img
                      src="assets/imgs/testimonials.png"
                      alt="Asia Software Solutions"
                      className={`w-100 testimonial-img position-relative`}
                    />
                  </div>
                  <div className={`testimonial-patch`}>
                    <span>TESTIMONIALS</span>
                    <p>We help to achieve common goals.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-sm-12 px-md-0 slider-content">
                <div
                  className={`card
                col-md-12`}
                >
                  <div
                    id="carouselControls"
                    className="carousel slide"
                    data-ride="carousel"
                    data-interval="100000"
                  >
                    <div className="w-100 carousel-inner" role="listbox">
                      {
                        testimonialData.map((item, idx) => {
                          return (
                            <div className={`carousel-item ${activeIndex === idx ? 'active' : ''}`} key={idx}>
                              <div className={`carousel-caption`}>
                                <div className={`carousel-side-div`}>
                                  <p className={`carousel-h`}>Words from clients</p>
                                  <small className="me-4">
                                    {item.feedback}
                                  </small>
                                  <div className={`company-info px-0`}>
                                    <img
                                      src="assets/imgs/testimonial.png"
                                      alt="Asia Software Solutions"
                                      className="rounded-circle img-fluid"
                                    />
                                    <div>
                                      <p>
                                        <b>{item.name}</b>
                                      </p>
                                      <p>{item.position}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div >
                    <div className={`move-icons mr-5`}>
                      <a
                        role="button"
                        data-slide="prev"
                        onClick={() => handlePrevSlide()}
                      >
                        <span
                          className={`carousel-control-prev-icon`}
                          aria-hidden="true"
                        >
                          <img
                            src="assets/imgs/path-copy.png"
                            alt="Asia Software Solutions"
                          />
                        </span>
                      </a>
                      <a
                        role="button"
                        data-slide="next"
                        onClick={() => handleNextSlide()}
                      >
                        <span
                          className={`carousel-control-next-icon`}
                          aria-hidden="true"
                        >
                          <img
                            src="assets/imgs/path.png"
                            alt="Asia Software Solutions"
                          />
                        </span>
                      </a>
                    </div>
                  </div >
                </div >
              </div >
            </div >
          </div >
        </div >
      </div>
    </Fragment>
  );
};

export default Home;