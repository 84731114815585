import { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import "./header.scss";

const Header = () => {
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [scroll, setScroll] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [width, height] = useWindowSize();
  const location = useLocation()

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    if (width > 992 && showMenu) {
      setShowMenu(!showMenu)
    }
  }, [width, showMenu])

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }, [location])

  const handleClick = () => {
    setShowMenu(!showMenu)
  }

  const handleRouting = () => {
    if (showMenu) {
      setShowMenu(false)
    }
  }

  return (
    <div>
      <header className="header">
        <nav
          className={`navbar navbar-expand-lg fixed-top bg-white p-0 mb-5 ${scroll ? "fixed-header" : "navbar"
            }`}
        >
          <div className={`small-scr-logo`}>
            <Link to="/">
              <img
                src="../../../assets/imgs/asia-logo.png"
                alt="Asia Software Solutions"
              />
            </Link>
          </div>
          <div className="navbar-toggler-right">
            <button
              className={`navbar-toggler`}
              type="button"
              data-toggle="collapse"
              data-target="#navbar"
              aria-controls="navbarToggler"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" onClick={() => handleClick()}>
                <i className="fa fa-bars text-dark" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div className={`collapse navbar-collapse flex-column ${showMenu && 'show'}`} id="navbar">
            <div
              className={`upper-nav row
              justify-content-between
              align-items-center
              w-100
              m-0
              px-5
              upper-nav`}
            >
              <ul className={`contact-info navbar-nav px-3 contact-info`}>
                <li className={`nav-item`}>
                  <Link className={`nav-link`} to={{ pathname: "mailto:info@asiasoftwaresolutions.com" }} target="_top" onClick={() => handleRouting()}>
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    info@asiasoftwaresolutions.com
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link className={`nav-link`} to="/" onClick={() => handleRouting()}>
                    <i className="ri-phone-line phone"></i>
                    +(0172) 419 3317 / +91 76268-68507
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav px-3">
                <li className="nav-item active">
                  <Link className={`nav-link`} to="/" onClick={() => handleRouting()}>
                    <span>Home</span>
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link className={`nav-link custom-margin`} to="/careers" onClick={() => handleRouting()}>
                    <span className="border-0">Careers</span>
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link className={`nav-link get-quote`} to="/get-quote" onClick={() => handleRouting()}>
                    <i className="fa fa-comment-o mx-1" aria-hidden="true"></i>{" "}
                    Get Quote
                  </Link>
                </li>
              </ul>
            </div>
            <div
              className={`bottom-nav row
              header
              row
              justify-content-between
              align-items-center
              w-100
              m-0
              px-5
              bottom-nav`}
            >
              <ul className="navbar-nav ps-3">
                <li className={`nav-item asia-logo my-1`}>
                  <Link className={`nav-link ps-0`} to="/" onClick={() => handleRouting()}>
                    <img
                      src="../../../assets/imgs/asia-logo.png"
                      alt="Asia Software Solutions"
                    />
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav px-3">
                <li className={`nav-item`}>
                  <Link className={`nav-link`} to="/" onClick={() => handleRouting()}>
                    HOME
                    <div className={`nav-border`}>
                      <img
                        src="../../../assets/imgs/border-bottom.png"
                        alt="Asia Software Solutions"
                      />
                    </div>
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link className={`nav-link`} to="/about" onClick={() => handleRouting()}>
                    ABOUT US
                    <div className={`nav-border`}>
                      <img
                        src="../../../assets/imgs/border-bottom.png"
                        alt="Asia Software Solutions"
                      />
                    </div>
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link className={`nav-link`} to="/#home-services" onClick={() => handleRouting()}>
                    SERVICES
                    <div className={`nav-border`}>
                      <img
                        src="../../../assets/imgs/border-bottom.png"
                        alt="Asia Software Solutions"
                      />
                    </div>
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link className={`nav-link`} to="/portfolio" onClick={() => handleRouting()}>
                    OUR PORTFOLIO
                    <div className={`nav-border`}>
                      <img
                        src="../../../assets/imgs/border-bottom.png"
                        alt="Asia Software Solutions"
                        className={`link-border`}
                      />
                    </div>
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link
                    className={`nav-link`}
                    to="/contact"
                    style={{ marginRight: "1rem" }}
                    onClick={() => handleRouting()}
                  >
                    CONTACT US
                    <div className={`nav-border`}>
                      <img
                        src="../../../assets/imgs/border-bottom.png"
                        alt="Asia Software Solutions"
                        className={`link-border`}
                      />
                    </div>
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link className={`margin-r0 nav-link`} to={{ pathname: "https://www.facebook.com/asiasoftwaresolutions" }} target="_blank" onClick={() => handleRouting()}>
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link className={`margin-r0 nav-link`} to={{ pathname: "https://twitter.com/asiasoftsol" }} target="_blank" onClick={() => handleRouting()}>
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link className={`margin-r0 nav-link`} to={{ pathname: "https://www.linkedin.com/company/asiasoftwaresolutions" }} target="_blank" onClick={() => handleRouting()}>
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link className={`margin-r0' nav-link me-0`} to={{ pathname: "https://www.instagram.com/asiasoftsol/" }} target="_blank" onClick={() => handleRouting()}>
                    <i
                      className="ri-instagram-fill"
                      style={{ padding: "6px" }}
                    ></i>
                  </Link >
                </li >
              </ul >
            </div >
          </div >
        </nav >
      </header >
    </div >
  );
};

export default Header;
