import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import "../Home/home.scss";

const AspNetDeveloper = () => {
  return (
    <Fragment>
      <Helmet>
        <title>ASP.NET Developers</title>
        <meta name="description" content="ASP.NET Developers" />
      </Helmet>
      <div>
        <div className={`hire-ui`}>
          <div className={`main-banner`} style={{ backgroundImage: `url("../../../assets/imgs/page-bg.png")` }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-7 col-sm-12 pe-4">
                  <div className={`left-side-info`}>
                    <h1 className="mb-3">Hire Affordable ASP.NET Developers</h1>
                    <p className="pe-md-5 me-lg-5">
                      Hire dedicated ASP.NET developers from Asia Software
                      Solutions, a leading web development company, to save big
                      infrastructure and overhead costs for all web requirements.
                      Our certified DOT NET programmers can unleash the potential
                      of .NET platform to build business-driven web solutions
                      leveraging relevant and trending technologies.
                    </p>
                  </div>
                </div>

                <div className="col-md-5 col-sm-12 mt-md-0 mt-4">
                  <img
                    src="../../../assets/imgs/about-asset-img.png"
                    className="img-width"
                    alt="Asia Software Solutions"
                  />
                </div>
              </div>
            </div>
          </div>


          <div className={`dev-process custom-padding`}>
            <div className="container">
              <div className="text-center">
                <span className="small-font-head">WHY DO PEOPLE CHOOSE US?</span>
                <h5 className={`section-heading mt-4 d-grid`}>
                  <span>Advantages of Hiring Asp.Net Developers</span>{" "}
                  <span>@asiasoftsol</span>
                </h5>
              </div>
              <div className={`row row-equal-h`}>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Custom ASP.NET Development</p>
                    <p>
                      Skilled with ceating high impact,user centred websites and applications in different ASP.net frameworks.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d1.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Sharepoint Development</p>
                    <p>
                      Assisting your business and its employees share, collaborate
                      and retrieve information and create custom Share point
                      solutions.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d2.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>ASP .NET MVC Developers</p>
                    <p>
                      Our ASP .Net developers provide MVC3/MVC4 development
                      services to maximize control and enhance customer
                      experience.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d3.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Enteprise Solutions for ASP .NET</p>
                    <p>
                      Our developers are capable of delivering top-class
                      enterprise level web apps by working on the latest ASP.net
                      platform.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d4.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Dynamic CRM</p>
                    <p>
                      Our developers offer CRM services that help your
                      organization increase productivity, streamline business
                      processes, and build better relationships.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12">
                  <div className={`main-box`}>
                    <img
                      src="../../../assets/imgs/hire-d5.svg"
                      alt="Asia Software Solutions"
                    />
                    <p className={`main-h`}>Asp.Net Migration Programmers</p>
                    <p>
                      Our ASP.NET experts helps you integrate ASP.NET with other
                      Microsoft technologies without compromising on data and
                      security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AspNetDeveloper;
