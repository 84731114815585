import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Portfolio from "./components/Portfolio";
import About from "./components/About";
import Contact from "./components/Contact";
import Hire from "./components/Hire";
import "./App.css";
import ReactDeveloper from "./components/ReactDev";
import AspNetDeveloper from "./components/ASP.NETDev";
import AngularJSDeveloper from "./components/AngularJS";
import Careers from "./components/Careers";
import HtmlDev from "./components/HTML.Dev";
import CareersDetail from "./components/Position";
import ScrollToTop from "./components/ScrollToTop";
import GetQuote from "./components/GetQuote";
import ReactNativeDeveloper from "./components/ReactnativeDev";
import IosDeveloper from "./components/IOSDev";


const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/portfolio" exact component={Portfolio} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/hire" exact component={Hire} />
        <Route path="/services/reactdevelopers" exact component={ReactDeveloper} />
        <Route path="/services/reactnativedevelopers" exact component={ReactNativeDeveloper} />
        <Route path="/services/.netdevelopers" exact component={AspNetDeveloper} />
        <Route path="/services/angulardev" exact component={AngularJSDeveloper} />
        <Route path="/services/IosDeveloper" exact component={IosDeveloper} />
        <Route path="/Careers" exact component={Careers} />
        <Route path="/services/htmldev" exact component={HtmlDev} />
        <Route path="/career-detail" exact component={CareersDetail} />
        <Route path="/get-quote" exact component={GetQuote} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
