import React, { Fragment, useState } from "react";
import "./contact.scss";
import "../Portfolio/portfolio.scss";
import "../Home/home.scss";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import SendMsgModal from "../Modals/SendMsgModal";
import { ApiService } from "../../utilities/api.service";

interface FormData {
  email: string;
  message: string;
  name: string;
  subject: string;
}

const Contact = () => {
  const [openModal, setOpenModal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: FormData) => {
    try {
      ApiService.sendContactDetails(`contact/contact-us`, data).then((res) => {
        if (res.status === 200) {
          setOpenModal(true)
        }
      })
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Contact" />
      </Helmet>
      <div>
        <div className="contact-ui">
          <section
            className="main-banner"
            style={{ backgroundImage: `url("assets/imgs/page-bg.png")` }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-12">
                  <div className="left-side-info">
                    <h1>
                      To make requests for discussion, contact us via our social
                      channels.
                    </h1>
                    <p className="mb-0">We just need a couple of hours!</p>
                    <p>
                      No more than 2 working days since receiving your issue
                      ticket.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 my-4 my-md-0">
                  <img
                    src="assets/imgs/contact-banner.png"
                    className="w-100"
                    alt="Asia Software Solutions"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="contact-items-ui py-md-5 py-4">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-12 mobile-scr-order">
                  <img
                    src="assets/imgs/talk-img.png"
                    className="w-100"
                    alt="Asia Software Solutions"
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <form
                    className="contact-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <h2>
                      <b>Let’s talk</b>
                    </h2>
                    <p className="mb-4">
                      Send us an email and we will get back to you very soon.
                    </p>
                    <div>
                      <div className="row align-items-center">
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name *"
                              {...register("name", {
                                required: true,
                                pattern: /^[a-zA-Z][a-zA-Z ]*$/,
                              })}
                            />
                            {errors?.name?.type === "pattern" && (
                              <p>Alphabetical characters only</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email Address *"
                              {...register("email", {
                                required: true,
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please enter a valid email",
                                },
                              })}
                            />
                            {errors?.emailId?.message && (
                              <p>{errors.emailId?.message}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Subject *"
                              {...register("subject", {
                                required: true,
                                maxLength: 20,
                              })}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <textarea
                              className="form-control h-auto"
                              placeholder="Please describe what you need *"
                              rows={4}
                              {...register("message", {
                                required: true,
                                maxLength: 50,
                              })}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-12 mt-4">
                          <button
                            type="submit"
                            className="btn"
                          // data-bs-toggle="modal"
                          // data-bs-target="#send-msg-modal"
                          >
                            SEND MESSAGE
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          <div className={`customers mt-5`} style={{ height: "auto" }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-6 col-sm-12">
                  <img
                    src="assets/imgs/customers.png"
                    alt="Asia Software Solutions"
                    className="w-100"
                  />
                </div>
                <div className="col-md-6 col-lg-4 col-sm-12 offset-lg-1">
                  <div className={`ratings-info`}>
                    <h3>4.9/5.0</h3>
                    <div className="my-4">
                      <span>
                        <i className="fa fa-star me-1" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star me-1" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star me-1" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star me-1" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-star me-1" aria-hidden="true"></i>
                      </span>
                    </div>
                    <p>by 700+ customers for 3200+ clients</p>
                  </div>
                  <div className={`ps-4 company-contacts`}>
                    <div className={`d-flex align-items-center address mt-4`}>
                      <div className={`contact-div`}>
                        <img
                          src="assets/imgs/mail-fill.svg"
                          alt="Asia Software Solutions"
                          className={`address-icon`}
                        />
                      </div>
                      <p className={`contact-items ms-3 mb-0`}>
                        <span className={`small-font-span`}> SAY HELLO</span>
                        <span>
                          <b>info@asiasoftwaresolutions.com</b>
                        </span>
                      </p>
                    </div>

                    <div
                      className={`address d-flex align-items-center address mt-4`}
                    >
                      <div className={`contact-div`}>
                        <img
                          src="assets/imgs/phone.svg"
                          alt="Asia Software Solutions"
                          className={`address-icon`}
                        />
                      </div>
                      <p className={`contact-items ms-3 mb-0`}>
                        <span className={`small-font-span`}>
                          CALL FOR ADIVCE NOW
                        </span>
                        <span>
                          <b> +(0172) 429 3317</b> / <b> +91 76268-68507</b>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal html */}
        <SendMsgModal open={openModal} closeModal={() => setOpenModal(false)} />
      </div>
    </Fragment>
  );
};

export default Contact;
