import React from "react";
import "../Home/home.scss";
import aboutImg from "./images/about-asset-img.png";

const ReactnativeDeveloper = () => {
  return (
    <div>
      <div className={`hire-ui`}>
        <div className={`main-banner`} style={{ backgroundImage: `url("../../../assets/imgs/page-bg.png")` }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 col-sm-12 pe-4">
                <div className={`left-side-info`}>
                  <h1 className="mb-3">Hire React Native App Developers</h1>
                  <p className="pe-md-5 me-lg-5">
                    We simplify every software development process by thinking
                    from your perspectives. And even the process of hiring
                    dedicated developers is not an exception. We are committed
                    to help you with 100% integrity, right from selecting a
                    developer of your choice to the final product delivery.
                    IndiaNiC is just the right place to hire React Native
                    developers for onsite and offsite requirements, on a full
                    time, part-time or on pay-as-you-go (hourly) basis.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-12 mt-md-0 mt-4">
                <img
                  src={aboutImg}
                  className="img-width"
                  alt="Asia Software Solutions"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`dev-process custom-padding`}>
          <div className="container">
            <div className="text-center">
              <span className="small-font-head">SERVICES YOU CAN GET BY</span>
              <h5 className={`section-heading mt-4 d-grid`}>
                <span>Hiring Expert React Native App Developers</span>{" "}
                {/* <span>@asiasoftsol</span> */}
              </h5>
            </div>
            <div className={`row row-equal-h`}>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="../../../assets/imgs/hire-d.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>
                    React Native Mobile App Development
                  </p>
                  <p>
                    From PoCs to full-fledged mobile app, we offer React Naive
                    development services for iOS {"&"}
                    Android apps using a single code.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="../../../assets/imgs/hire-d1.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>UI/UX Design for React Native App</p>
                  <p>
                    We will start with wireframes and present to you. The coding
                    begins once you select from the shortlisted designs.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="../../../assets/imgs/hire-d2.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>Custom Libraries Development</p>
                  <p>
                    We build libraries/ modules to support components to
                    increase app capabilities for the desired native experience.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="../../../assets/imgs/hire-d3.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>React Native App Testing</p>
                  <p>
                    A comprehensive check is performed to ensure that coding is
                    efficient and bug-free that meets client expectations.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="../../../assets/imgs/hire-d4.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>Server-Side APIs Integration</p>
                  <p>
                    We deliver secure, adaptable server-side APIs and integrate
                    them with mobile applications to make them robust and
                    dynamic.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="../../../assets/imgs/hire-d5.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>
                    App Maintenance {"&"}
                    Support
                  </p>
                  <p>
                    We provide a contract system and support services to make
                    React Native apps compatible with all the devices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReactnativeDeveloper;
