import React from "react";
import "../Home/home.scss";
import aboutImg from "./images/about-asset-img.png";

const ReactnativeDeveloper = () => {
  return (
    <div>
      <div className={`hire-ui`}>
        <div className={`main-banner`}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 col-sm-12 pe-4">
                <div className={`left-side-info`}>
                  <h1 className="mb-3">
                    We Build High Performing iOS App with Intuitive UI/UX
                  </h1>
                  <p className="pe-md-5 me-lg-5">
                    Whether you need a full time or part time ios application
                    developer, you can be assured of IndiaNiC's expansive
                    development practices to build high impact, result driven
                    ios apps for iPhones, iPads, and iWatches. Our Certified iOS
                    Developers work exclusively on your iOS development project.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-12 mt-md-0 mt-4">
                <img
                  src={aboutImg}
                  className="img-width"
                  alt="Asia Software Solutions"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`dev-process custom-padding`}>
          <div className="container">
            <div className="text-center">
              <span className="small-font-head">OUR OFFERINGS</span>
              <h5 className={`section-heading mt-4 d-grid`}>
                <span>Hire Certified ios App Developers</span>{" "}
                {/* <span>@asiasoftsol</span> */}
              </h5>
            </div>
            <div className={`row row-equal-h`}>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>Custom ios Development</p>
                  <p>
                    Creating user-centered iOS apps as per various business{" "}
                    {"&"} user needs using trending technologies to deliver the
                    quality you've always dreamed of.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d1.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>
                    Prototyping, Wireframes {"&"} Mockups
                  </p>
                  <p>
                    We will start with wireframe designs for a start and present
                    to you. The development begins once you select from the
                    shortlisted wireframe designs.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d2.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>
                    iOS App Upgrade {"&"}
                    Maintenance
                  </p>
                  <p>
                    Enhance your existing iOS apps as well as get full
                    maintenance support by our experience ios developers,
                    whenever and wherever you need it.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d3.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>UI/UX Designing for ios Apps</p>
                  <p>
                    Along with innovate Ul get compelling and functional UX
                    design to transform your ideas into meaningful user stories.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d4.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>
                    Expert Coding {"&"}
                    Debugging
                  </p>
                  <p>
                    We ensure agile development for high-quality development
                    along with professional software testing for bug-free {"&"}{" "}
                    fast release.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d5.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>Testing {"&"} Cloud Integration</p>
                  <p>
                    Our experts help you connect various applications and
                    systems along with automated iOS app cloud testing and
                    integration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReactnativeDeveloper;
