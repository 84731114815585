import React from "react";
import "./hire-developer.scss";
import "../Home/home.scss";
import { Link } from "react-router-dom";

const Hire = () => {
  return (
    <div>
      <div className={`hire-ui`}>
        <div className={`main-banner`}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 col-sm-12 pe-4">
                <div className={`left-side-info`}>
                  <h1 className="mb-3">Hire Dedicated Developers</h1>
                  <p className="pe-md-5 me-lg-5">
                    We help global startups and enterprises to hire dedicated
                    developer teams for custom software development
                    requirements. Create your expert team with 4+ years of
                    average experience in various technology domains, efficient
                    communication, and timely updates for guaranteed deliveries.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-12 mt-md-0 mt-4">
                <img
                  src="assets/imgs/about-asset-img.png"
                  className="img-width"
                  alt="Asia Software Solutions"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`dev-process custom-padding`}>
          <div className="container">
            <div className="text-center">
              <span className="small-font-head">WHY DO PEOPLE CHOOSE US?</span>
              <h5 className={`section-heading mt-4 d-grid`}>
                <span>Advantages of Hiring Developers</span>{" "}
                <span>@asiasoftsol</span>
              </h5>
            </div>
            <div className={`row row-equal-h`}>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>Chosen Developers</p>
                  <p>
                    Choose your own development team through the vetted process
                    of screening and interviews. We will present to you the
                    resumes of the most appropriate candidates to select from.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d1.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>Direct Communication</p>
                  <p>
                    You will get full access to talk with chosen developers
                    through Skype, email, and phone with audio/video
                    conferencing options to overcome the communication barrier.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d2.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>ZERO Upfront Cost</p>
                  <p>
                    We help you to onboard the team with ZERO cost regarding
                    infrastructure, hiring and staffing. You will only need to
                    pay for the engagement period with the chosen team of yours.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d3.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>Full Project Control</p>
                  <p>
                    Clients will have complete control over the hired
                    development team. The hired development team will work
                    directly with you to plan, develop, deliver the product that
                    meets business needs.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d4.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>100% Transparency</p>
                  <p>
                    One of the best aspects of a dedicated development model is
                    to have timely project updates to realize the state of it.
                    This helps in precise forecasting to launch while
                    prioritizing the releases.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className={`main-box`}>
                  <img
                    src="assets/imgs/hire-d5.svg"
                    alt="Asia Software Solutions"
                  />
                  <p className={`main-h`}>Plug 'N Play</p>
                  <p>
                    Build your team virtually in no time. You have the
                    flexibility to scale up {"&"} scale down the team via a
                    notice just a month before to optimize the costs and
                    engagement per resource.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`offers custom-padding`}>
          <div className="container">
            <div className="text-center">
              <span className="small-font-head">OUR OFFERINGS</span>
              <h5 className={`section-heading mb-3 text-dark d-grid`}>
                <span>Create Your Own Team Now</span>
              </h5>
            </div>
            <div className={`mobile-tab-ui pt-4`}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/" className="hiring-box-link">
                      <img
                        src="assets/imgs/ios.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">Hire iOS App Developers</p>
                    </Link>
                  </div>
                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/" className="hiring-box-link">
                      <img
                        src="assets/imgs/android.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">Hire Androind Developers</p>
                    </Link>
                  </div>
                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/" className="hiring-box-link">
                      <img
                        src="assets/imgs/react.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">Hire Mobile App Developers</p>
                    </Link>
                  </div>

                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/" className="hiring-box-link">
                      <img
                        src="assets/imgs/titanium.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">Hire Website Designers</p>
                    </Link>
                  </div>

                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/" className="hiring-box-link">
                      <img
                        src="assets/imgs/ionic.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">Hire Open Source Developers</p>
                    </Link>
                  </div>
                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/" className="hiring-box-link">
                      <img
                        src="assets/imgs/ios.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">Hire PHP Web Developers</p>
                    </Link>
                  </div>
                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/" className="hiring-box-link">
                      <img
                        src="assets/imgs/kotlin.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">Hire Magento Developers</p>
                    </Link>
                  </div>
                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/" className="hiring-box-link">
                      <img
                        src="assets/imgs/obj.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">Hire Wordpress Developers</p>
                    </Link>
                  </div>
                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/services/angulardev" className="hiring-box-link">
                      <img
                        src="assets/imgs/xamarin.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">Hire AngularJs Developers</p>
                    </Link>
                  </div>
                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/" className="hiring-box-link">
                      <img
                        src="assets/imgs/xamarin.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">
                        Hire Java Software Developers
                      </p>
                    </Link>
                  </div>
                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/" className="hiring-box-link">
                      <img
                        src="assets/imgs/xamarin.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">Hire Drupal Developers</p>
                    </Link>
                  </div>
                  <div className={`col col-sm-3 col-4`}>
                    <Link to="/services/.netdevelopers" className="hiring-box-link">
                      <img
                        src="assets/imgs/flutter.svg"
                        alt="Asia Software Solutions"
                      />
                      <p className="text-center">Hire ASP.NET Developers</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`main-banner mb-4 mt-0`} style={{ height: "auto" }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-9 col-12 text-center mx-auto">
                <div className="mx-auto hiring-info mb-4">
                  <p className="my-3">Our Hiring Models</p>
                  <span className="my-4">
                    To meet various project needs for any size of the business,
                    Asia Software Solutions offers full- time, part-time and
                    hourly hiring for dedicated development projects. Have a
                    look at the engagement models that results in meeting
                    various business demands.
                  </span>
                </div>
                <div className={`row row-equal-h mx-auto`}>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <div className={`main-box`}>
                      <p className={`main-h`}>Full Time</p>
                      <span>8 hours/day • 5 days/week</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <div className={`main-box`}>
                      <p className={`main-h`}>Part Time</p>
                      <span>4 hours/day • 5 days/week</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <div className={`main-box`}>
                      <p className={`main-h`}>Hourly Basis</p>
                      <span>Starts with 40 hours • Pay as you go</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hire;
